import {
  LocationOnOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import { Stack, TextField } from '@mui/material';
import { Location } from '@understory-io/utils-types';
import { debounce } from 'lodash';
import { useEffect } from 'react';
import { FormProvider, useController, useFormContext } from 'react-hook-form';

import { useYupForm } from '../../../Hooks/use-yup-form';
import {
  type BasicExperienceFields as BasicExperienceFieldsType,
  basicExperienceFieldsSchema,
} from '../schemas/basicExperienceFieldsSchema';
import { CustomSelect } from './components/custom-select';

type BasicExperienceFieldsProps = {
  locations: Location[];
  defaultValues: BasicExperienceFieldsType;
  onSubmit: (data: BasicExperienceFieldsType) => void;
};

const DEBOUNCE_SUBMIT_MS = 500;

export const BasicExperienceFields = ({
  locations,
  defaultValues,
  onSubmit,
}: BasicExperienceFieldsProps) => {
  const formMethods = useYupForm({
    schema: basicExperienceFieldsSchema,
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    watch,
    getValues,
    formState: {
      errors: { locationIds: locationIdsError, visibility: visibilityError },
    },
  } = formMethods;

  const { field: visibility } = useController({
    name: `visibility`,
    control,
    rules: { required: 'Visibility is required' },
  });

  const { field: locationIds } = useController({
    name: `locationIds`,
    control,
    rules: { required: 'One location is required' },
  });

  const handleVisibilityChange = (newValue: string | string[]) => {
    if (Array.isArray(newValue)) return;

    visibility.onChange(newValue);
  };

  useEffect(() => {
    // Debounce submission of form
    const debouncedSubmit = debounce(
      // We still want to submit on handleSubmit's onInvalid handler
      handleSubmit(onSubmit, () => onSubmit(getValues())),
      DEBOUNCE_SUBMIT_MS
    );
    // Subscribe to form changes
    const subscription = watch(() => debouncedSubmit());

    return () => subscription.unsubscribe();
  }, [getValues, handleSubmit, onSubmit, watch]);

  return (
    <FormProvider {...formMethods}>
      <Stack
        component="form"
        noValidate
        sx={{ gap: 2, alignItems: 'flex-start' }}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            gap: { xs: 5, md: 10 },
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          <TitleInput />
          <CustomSelect
            selectedValue={visibility.value}
            options={[
              { key: 'public', label: 'Public' },
              { key: 'private', label: 'Private' },
            ]}
            onChange={handleVisibilityChange}
            StartAdornment={
              visibility.value === 'public'
                ? VisibilityOutlined
                : VisibilityOffOutlined
            }
            error={!!visibilityError}
            helperText={visibilityError?.message}
          />
        </Stack>
        <CustomSelect
          multiple
          selectedValue={locationIds.value}
          options={locations.map(({ locationId, locationName }) => ({
            key: locationId,
            label: locationName,
          }))}
          onChange={locationIds.onChange}
          emptyLabel="Select location(s)"
          StartAdornment={LocationOnOutlined}
          error={!!locationIdsError}
          helperText={locationIdsError?.message}
        />
      </Stack>
    </FormProvider>
  );
};

const TitleInput = () => {
  const {
    control,
    formState: {
      errors: { headline: headlineError },
    },
  } = useFormContext<BasicExperienceFieldsType>();

  const { field: headline } = useController({
    name: 'headline',
    control,
    // TODO: Remove when validation schema is implemented
    rules: {
      required: 'Headline is required',
      maxLength: {
        value: 50,
        message: 'Headline must not exceed 50 characters',
      },
    },
  });

  return (
    <TextField
      multiline
      value={headline.value}
      onChange={(e) => headline.onChange(e.target.value)}
      autoFocus={!headline.value}
      variant="standard"
      fullWidth
      placeholder="Experience title"
      inputProps={{ sx: { fontSize: 44, lineHeight: '100%' } }}
      error={!!headlineError}
      helperText={headlineError?.message}
    />
  );
};
