import { MenuItem, Select, Stack, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { lightTheme, Text } from '@understory-io/pixel';

type CustomSelectProps = {
  selectedValue: string | string[];
  options: { key: string; label: string }[];
  onChange: (value: string | string[]) => void;
  multiple?: boolean;
  emptyLabel?: string;
  StartAdornment?: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
    muiName: string;
  };
  error?: boolean;
  helperText?: string;
};

export const CustomSelect = ({
  selectedValue,
  options,
  onChange,
  multiple = false,
  emptyLabel,
  StartAdornment,
  error,
  helperText,
}: CustomSelectProps) => {
  return (
    <Stack sx={{ gap: 0.5 }}>
      <Select
        multiple={multiple}
        value={selectedValue}
        onChange={(e) => onChange(e.target.value)}
        inputProps={{
          sx: {
            paddingY: '6.5px',
            paddingX: 1.5,
          },
        }}
        displayEmpty
        renderValue={(value) => (
          <Stack
            sx={{
              flexDirection: 'row',
              gap: 1,
              color: lightTheme.palette.neutral.n300,
            }}
          >
            {StartAdornment && (
              <StartAdornment
                fontSize="small"
                sx={{ color: lightTheme.palette.neutral.n300 }}
              />
            )}
            <Text fontSize="small" color={lightTheme.palette.contrast.black}>
              {renderSelectValue(value, options, multiple, emptyLabel)}
            </Text>
          </Stack>
        )}
        size="small"
        error={error}
      >
        {options.map(({ key, label }) => (
          <MenuItem key={key} value={key}>
            {label}
          </MenuItem>
        ))}
      </Select>
      {helperText && (
        <Text fontSize="xsmall" color={lightTheme.palette.error.e400}>
          {helperText}
        </Text>
      )}
    </Stack>
  );
};

const renderSelectValue = (
  value: CustomSelectProps['selectedValue'],
  options: CustomSelectProps['options'],
  multiple: CustomSelectProps['multiple'],
  emptyLabel: CustomSelectProps['emptyLabel']
) => {
  if (!value || value.length === 0) return emptyLabel;

  if (Array.isArray(value) && multiple) {
    if (value.length > 1) {
      return `${value.length} selected`;
    }
    return options.find((option) => option.key === value[0])?.label;
  }

  return options.find((option) => option.key === value)?.label;
};
