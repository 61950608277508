import {
  AddOutlined,
  ArticleOutlined,
  EditOutlined,
  SnoozeOutlined,
} from '@mui/icons-material';
import { Stack, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { lightTheme, Text } from '@understory-io/pixel';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { Language } from '../../../i18n/config';
import routes from '../../../Utils/routes';
import { Experience } from '../schemas';
import { renderDurationString } from '../utils/render-duration';
import { useNavigateWithParams } from './utils/use-navigate-with-params';

type DetailsOverviewProps = {
  experience: Experience;
  activeLanguage: Language;
};

export const DetailsOverview = ({
  experience,
  activeLanguage,
}: DetailsOverviewProps) => {
  const { t } = useTranslation();

  const baseRoute = routes.experience.details(experience.id).edit;

  const navigateWithParams = useNavigateWithParams();

  return (
    <Stack sx={{ gap: 1 }}>
      <DetailItem
        title={
          experience.practicalInfo[activeLanguage]
            ? 'Description'
            : 'Add a description'
        }
        Icon={ArticleOutlined}
        onClick={() => navigateWithParams(baseRoute.description)}
      >
        {experience.practicalInfo[activeLanguage] && (
          <Text fontSize="small" color={lightTheme.palette.neutral.n300}>
            {experience.practicalInfo[activeLanguage]}
          </Text>
        )}
      </DetailItem>

      <DetailItem
        title="Cut-off time"
        Icon={SnoozeOutlined}
        onClick={() => navigateWithParams(baseRoute.cutoff)}
        selectedValue={renderDurationString(
          t,
          experience.cutoffTimeSeconds / 60
        )}
      />
    </Stack>
  );
};

type DetailItemProps = PropsWithChildren & {
  title: string;
  Icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>>;
  onClick: () => void;
  selectedValue?: string;
};

const DetailItem = ({
  title,
  Icon,
  onClick,
  selectedValue,
  children,
}: DetailItemProps) => {
  const hasValue = selectedValue || children;

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        cursor: hasValue ? 'default' : 'pointer',
        padding: 2,
        backgroundColor: lightTheme.palette.contrast.white,
        borderRadius: 1,
        gap: 1,
        transition: 'background-color 0.1s',
        ':hover': !hasValue
          ? {
              backgroundColor: lightTheme.palette.neutral.n100,
            }
          : undefined,
      }}
      onClick={hasValue ? undefined : onClick}
    >
      <Icon sx={{ color: lightTheme.palette.neutral.n300 }} fontSize="small" />
      <Stack
        sx={{
          gap: 1,
          flexGrow: 1,
        }}
      >
        <Text fontSize="small">{title}</Text>
        {children}
      </Stack>
      <Stack sx={{ flexDirection: 'row', gap: 1 }}>
        {selectedValue && (
          <Text fontSize="small" color={lightTheme.palette.neutral.n300}>
            {selectedValue}
          </Text>
        )}
        {hasValue ? (
          <EditOutlined
            sx={{
              color: lightTheme.palette.neutral.n300,
              cursor: 'pointer',
              transition: 'color 0.1s',
              ':hover': { color: lightTheme.palette.neutral.n500 },
            }}
            fontSize="small"
            onClick={onClick}
          />
        ) : (
          <AddOutlined
            sx={{ color: lightTheme.palette.neutral.n300 }}
            fontSize="small"
          />
        )}
      </Stack>
    </Stack>
  );
};
