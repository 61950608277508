import { lightTheme, Text } from '@understory-io/pixel';
import { useState } from 'react';
import {
  type ActionFunctionArgs,
  useActionData,
  useRouteLoaderData,
} from 'react-router';
import { ValidationError } from 'yup';

import { RichEditor } from '../../../../Components/RichEditor/RichEditor';
import { Language } from '../../../../i18n/config';
import { practicalInfoSchema } from '../../schemas/practicalInfoSchema';
import { LoaderData, loaderName } from '../edit-experience';
import { getCompanyProfile, getExperience } from '../queries';
import { getFormDataValue } from '../utils/form-helpers';
import { getActiveLanguage } from '../utils/get-active-language';
import { getAutoTranslate } from '../utils/get-auto-translate';
import { translateInput } from '../utils/translate-input';
import { EditExperienceDialog } from './edit-experience-dialog';

const PROPERTY_NAME = 'practicalInfo';

export default function PracticalInfoForm() {
  const actionData = useActionData() as ActionData;
  const { experience, activeLanguage } = useRouteLoaderData(
    loaderName
  ) as LoaderData;

  const [practicalInfo, setPracticalInfo] = useState(
    experience.practicalInfo[activeLanguage]
  );

  return (
    <EditExperienceDialog
      title="Practical information"
      description="Add any practical information for the guests to know post booking. These informations will only be in the booking confirmation email."
      shouldClose={actionData?.shouldClose}
      experienceId={experience.id}
    >
      <input name={PROPERTY_NAME} type="hidden" value={practicalInfo} />
      <RichEditor
        placeholder="E. g. please arrive 15 minutes early ..."
        value={practicalInfo}
        onChange={(e: string) => {
          setPracticalInfo(e);
        }}
      />
      {actionData?.error && (
        <Text color={lightTheme.palette.error.e400} variant="normal">
          {actionData.error}
        </Text>
      )}
    </EditExperienceDialog>
  );
}

type ActionData = {
  shouldClose?: boolean;
  error?: string;
};

export async function action({ params, request }: ActionFunctionArgs) {
  const id = params.id;
  const autoTranslate = getAutoTranslate(request);

  if (!id) {
    throw new Response('Invalid id', { status: 404 });
  }

  try {
    // Validate input
    const formData = await request.formData();
    const practicalInfo = getFormDataValue(formData.get(PROPERTY_NAME));

    const [experience, companyProfile] = await Promise.all([
      getExperience(id),
      getCompanyProfile(),
    ]);

    const activeLanguage = getActiveLanguage(request, companyProfile);

    const translatedPracticalInfo = await translateInput(
      practicalInfo as string,
      experience.practicalInfo,
      activeLanguage,
      companyProfile.languages as Language[],
      autoTranslate
    );

    const result = practicalInfoSchema.validateSync(translatedPracticalInfo);

    const experienceToSave = {
      ...experience,
      practicalInfo: result,
    };

    console.log('Save experience in description action', experienceToSave);

    // 3. Save draft
    // TODO: Save draft - US-2713

    return { shouldClose: true };
  } catch (error) {
    if (error instanceof ValidationError) {
      return {
        error: error.message,
      };
    }

    return { shouldClose: true };
  }
}
