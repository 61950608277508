import { VisibilityOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Button } from '@understory-io/pixel';

import { useLanguages } from '../../../../Hooks/locales/use-languages';
import { isLanguage, Language } from '../../../../i18n/config';
import { Experience } from '../../schemas';
import { CustomSelect } from '../components/custom-select';
import { AutoSaveStatus } from './auto-save-status';
import { AutoTranslateToggle } from './auto-translate-toggle';
import { GoBack } from './go-back';

type EditExperienceHeaderProps = {
  experience: Experience;
  activeLanguage: Language;
  availableLanguages: Language[];
  handleChangeLanguage: (language: Language) => void;
  isAutoTranslateEnabled: boolean;
  handleChangeAutoTranslate: (checked: boolean) => void;
};

export const EditExperienceHeader = ({
  experience,
  activeLanguage,
  availableLanguages,
  handleChangeLanguage,
  isAutoTranslateEnabled,
  handleChangeAutoTranslate,
}: EditExperienceHeaderProps) => {
  const { languageOptions } = useLanguages();

  const onLanguageChange = (newValue: string | string[]) => {
    if (Array.isArray(newValue)) return;
    if (isLanguage(newValue)) {
      handleChangeLanguage(newValue);
    }
  };

  return (
    <Stack
      sx={{
        padding: 4,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 5,
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          gap: 5,
          alignItems: 'center',
        }}
      >
        <GoBack experienceId={experience.id} />
        <AutoSaveStatus />
      </Stack>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 3 }}>
        <AutoTranslateToggle
          isAutoTranslateEnabled={isAutoTranslateEnabled}
          onChange={handleChangeAutoTranslate}
        />
        <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 2 }}>
          <CustomSelect
            selectedValue={activeLanguage}
            options={availableLanguages.map((lang) => ({
              key: lang,
              label: languageOptions(lang).label,
            }))}
            onChange={onLanguageChange}
          />
          <Button
            variant="secondary"
            size="small"
            leftIcon={<VisibilityOutlined fontSize="small" />}
          >
            Preview
          </Button>
          <Button variant="primary" size="small">
            Publish
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
